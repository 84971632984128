import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';

import pluralize from "pluralize";
import { Link, navigate } from 'gatsby';
import { Helmet } from 'react-helmet';

import styled from '@emotion/styled/macro';
import classNames from 'classnames';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import useResizeObserver from 'use-resize-observer';
import FolderStructure from '../client/folderStructure';
import Sequence, { Chapter, SequenceStatus } from '../client/sequence';
import { LoaderDiv } from '../components/contentLibrary/libraryStyledComponent';
import { formatTime } from '../components/main-player';
import Share from '../components/share';
import SVG from '../components/svg';
import { PAGE_SIZE } from '../components/wrapWithProvider';
import { AppContext } from '../lib/app-context';
import { dragToFolderEndHandler, dragToFolderStartHandler, onDragHandler, onDropActions } from '../lib/utils';
import { handleError } from '../state/error';
import {
  appendSequences,
  loadedSequencesState,
  sequencesAmount,
  sequencesSharedCountState,
  sequencesTotalCountState,
  unloadSequence,
} from '../state/local';
import { sequenceSave } from '../state/sequence';
import CreateModal from '../ui-components/Folder/CreateModal';
import CreateProject from '../ui-components/CreateProject';
import Folder from '../ui-components/Folder/Folder';
import { sequencesInitializedState } from '../ui-components/Header/Header';
import ImageWithPlaceholder from '../ui-components/ImageWithPlaceholder/ImageWithPlaceholder';
import Loader from '../ui-components/Loader';
import OptionsMenuPopover from '../ui-components/OptionsMenu/OptionsMenuPopover';
import ProjectsCounterBlock from '../ui-components/ProjectsCounter/ProjectsCounterBlock';
import {
  pendingClonesState,
  useSequenceCloneOptions,
} from '../ui-components/SequenceCloneOptions/useSequenceCloneOptions';
import useSequenceTranslateOptions from '../ui-components/SequenceCloneOptions/useSequenceTranslateOptions';
import Tabs from '../ui-components/Tabs/Tabs';
import { trackEvent } from '../utils/analityics.utils';
import { onProjectTitleKeyPress } from '../utils/input.utils';
import './Library.scss';
import { FlagIcon } from '../ui-components/CustomLanguageSelector/CustomLanguageSelector';

const SequenceItemPlaceholder = styled.div`
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  max-width: 100%;

  img {
    width: -webkit-fill-available;
    max-width: 100%;
  }
`;

const SequenceItemPlaceholderOverlay = styled.div`
  content: '';
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  inset: 0;
  backdrop-filter: blur(5px);
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.2);

  .peech-loader {
    margin: 0 !important;
  }
`;

const SequenceTitle = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
`;

const AspectRatioLabel = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 auto;

  &:before {
    content: '';
    display: block;
    height: 12px;
    border-right: 1px solid currentColor;
    margin-right: 3px;
  }
`;

const PROJECTS = {
  SELF: 0,
  SHARED: 1,
};

const TABS = {
  PROJECTS: 0,
  SHARE_WITH_ME: 1,
};

const HOUR = 60 * 60;

/**
 * @param {{
 *  sequence: Sequence,
 *  onRemove: Function,
 *  onAddImage: Function,
 *  onSelect: Function,
 *  shouldShowActions: Boolean,
 * }} props
 */
function SequenceItem({
  sequenceItem,
  onRemove,
  onSelect,
  shouldShowActions,
  draggable,
  onDragStart,
  onDragEnd,
  scrollingContainerRef,
}) {
  const [isOpenInviteModal, setIsOpenInviteModal] = React.useState(false);
  const [verifyDelete, setVerifyDelete] = React.useState(false);
  const [rename, setRename] = React.useState(false);
  const titleField = React.useRef();
  const [sequence, setSequence] = React.useState(null);
  const { config, user, plan } = React.useContext(AppContext);
  const chapter = sequence?.chapters.filter((c) => !c.cuePointType).find((c) => c.status !== Chapter.STATUS.DELETED);
  const imageSrc = chapter?.thumbnailUrl(sequence, 200, config.CHUNKS_URL);
  const stopRef = React.useRef(true);
  const [darkOverlay, setDarkOverlay] = React.useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);

  if (sequence?.status === Sequence.STATUS.DELETED) {
    return null;
  }

  React.useEffect(() => {
    setSequence(sequenceItem);
    if (sequenceItem?.values?.status === SequenceStatus.TRANSLATING) {
      setDarkOverlay(true);
    } else {
      setDarkOverlay(false);
    }
  }, [sequenceItem, sequenceItem?.values?.status]);

  React.useEffect(() => {
    rename && titleField.current && titleField.current.focus();
  }, [rename]);

  async function removeSequence() {
    trackEvent('project-delete');
    try {
      await new Sequence(sequence.sid).remove();
      onRemove && onRemove();
      setVerifyDelete(false);
    } catch (err) {
      handleError({
        title: 'Deleting Project',
        message: err.message,
        responseError: err,
      });
    }
  }

  function onTitleBlur({ target }) {
    var title = target.innerText.trim();
    if (title.length && title !== sequence.title) {
      sequence.title = title;
      sequenceSave(sequence);
    } else {
      target.innerText = sequence.title;
    }
    setRename(false);
    target.scrollLeft = 0;
  }

  const seqDuration = React.useMemo(() => {
    if (!sequence?.duration) {
      return '00:00';
    }
    let sequenceTime = formatTime((sequence?.duration || 0) * 1000);
    return sequence.duration >= HOUR ? sequenceTime : sequenceTime.slice(3);
  }, [sequence?.duration]);

  const seqLang = React.useMemo(() => {
    if (!sequence || sequence?.status === SequenceStatus.TRANSLATING) {
      return null;
    } else if (!sequence.languageCode) {
      return 'En';
    }
    return sequence.languageCode.split('-')[0];
  }, [sequence?.languageCode, sequence?.status]);

  const actions = [
    {
      key: 'edit',
      icon: 'edit',
      text: 'Rename',
      toolTip: 'Rename Project',
      onClick: () => setRename(true),
    },
    {
      key: 'delete',
      icon: 'delete',
      text: 'Delete',
      toolTip: 'Delete Project',
      hide: sequence?.role !== Sequence.ROLE.CREATOR,
      onClick: () => setVerifyDelete(true),
    },
    {
      key: 'invite',
      icon: 'invite',
      text: 'Share',
      toolTip: 'Invite',
      onClick() {
        trackEvent('project-show-share-dialog', { trigger: 'project-conetx-menu' });
        setIsOpenInviteModal(true);
      },
    },
    ...useSequenceCloneOptions({ sequence, trigger: 'Project Library' }),
    ...useSequenceTranslateOptions({ sequence, trigger: 'Project Library' }),
  ];

  if (!sequence) {
    return null;
  }

  return (
    <Col xs="12" md="6" lg="4" xl="3" xxl="2" className="projects-col" data-cy="project-item">
      {verifyDelete && <DeleteConfirmation onHide={() => setVerifyDelete(false)} removeSequence={removeSequence} />}

      <div className={classNames('plug-pic', { [sequence.sid]: true })}>
        <img src={imageSrc} draggable={false} />
      </div>

      <div
        className="assets-item"
        // onClick={onItemClick}
        draggable={draggable}
        onDragStart={onDragStart}
        onDragEnd={(e) => {
          stopRef.current = true;
          onDragEnd(e);
        }}
        onDrag={(e) => onDragHandler(e, draggable, stopRef, scrollingContainerRef)}
      >
        <div className="assets-item-label">
          <div className="assets-item-label-wrapper">
            <SVG name="player-projects" className="player-projects" />
            <div className="dragged-asset-name">{sequence.title}</div>
          </div>
        </div>

        <div
          className="projects-content"
          style={{cursor: sequence?.status === SequenceStatus.ACTIVE && !isPopoverOpen ? 'pointer' : 'unset'}}
          onClick={() => {
            if (sequence?.status === SequenceStatus.ACTIVE && !isPopoverOpen) {
              onSelect(sequence.sid);
              navigate(`/project#${sequence?.sid}`);
            }
          }}
          draggable={false}
        >
          <div className="projects-item-thumb" data-cy="projects-item-thumb">
            {/* Options Menu*/}
            {sequence.role === Sequence.ROLE.CREATOR && sequence?.status === SequenceStatus.ACTIVE && (
              <div
                className="options-button"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <OptionsMenuPopover
                  options={actions}
                  scrollingContainerRef={scrollingContainerRef}
                  onOpen={() => {
                    setIsPopoverOpen(true);
                  }}
                  onClose={() => {
                    setIsPopoverOpen(false);
                  }}
                />
              </div>
            )}
            {/* Shared Icon */}
            {shouldShowActions && sequence.accessesCount > 1 && (
              <div className="projects-item-shared">
                <SVG name="invite" />
              </div>
            )}
            {/* Thumbnail */}
            <ImageWithPlaceholder
              source={imageSrc}
              alt={sequence.title}
              status={sequence.values.status}
              darkOverlay={darkOverlay}
              className="projects-item-img"
            />
            {/* Duration */}
            <div className="projects-item-duration">{seqDuration}</div>
            {/* Language mark */}
            {seqLang && (
              <div className="projects-item-language">
                <FlagIcon
                  code={sequence?.languageCode ? sequence.languageCode.split('-')[1] : 'US'}
                  style={{
                    width: '14px',
                    height: '9px',
                    minWidth: 'unset',
                    maxWidth: 'unset',
                    borderRadius: '0px',
                    marginRight: '4px',
                  }}
                />
                {seqLang}
              </div>
            )}
          </div>

          <div className="projects-item-header" style={{ marginTop: 0 }}>
            <OverlayTrigger
              placement="bottom-start"
              overlay={
                <Tooltip id="cut-included-tooltip" className="tooltip-peech-dark">
                  {sequence?.title || ''}
                </Tooltip>
              }
            >
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div
                  contentEditable={rename}
                  className="projects-item-title"
                  onKeyPress={onProjectTitleKeyPress}
                  onClick={(e) => {
                    if (rename) {
                      e.stopPropagation();
                      e.preventDefault();
                    }
                  }}
                  onBlur={onTitleBlur}
                  ref={titleField}
                >
                  {sequence?.title || ''}
                </div>

                <AspectRatioLabel className="projects-item-title">{sequence.aspectRatio}</AspectRatioLabel>
              </div>
            </OverlayTrigger>
          </div>
        </div>
      </div>
      {isOpenInviteModal && (
        <Modal
          show={true}
          id="sequence-invite-dialog"
          className="sequence-invite"
          aria-labelledby="contained-modal-title-vcenter"
          onHide={() => setIsOpenInviteModal(false)}
          centered
        >
          <Share isModal={true} onHide={() => setIsOpenInviteModal(false)} activeSequence={sequence} />
        </Modal>
      )}
    </Col>
  );
}

function EmptySequence() {
  return (
    <Col xs="12" sm="6" lg="4" xl="3" className="projects-col">
      <Link to="/">
        <div className="projects-item projects-empty">
          <p>+</p>
        </div>
      </Link>
      <div className="projects-empty-title" style={{ marginTop: '10px', marginLeft: '1px' }}>
        Create new video
      </div>
    </Col>
  );
}

function DeleteConfirmation({ onHide, removeSequence }) {
  return (
    <Modal
      show={true}
      id="delete-confirmation-dialog"
      className="project-delete"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={onHide}
      centered
    >
      <Modal.Body>
        <Modal.Title>Delete Confirmation</Modal.Title>
        <p>
          Once deleting a project, you will no longer have access to
          <br />
          edit that project anymore.
        </p>
        <p>
          <b>Are you sure you want to delete this project?</b>
        </p>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button id="project-delete-button" variant="danger" onClick={removeSequence}>
          Delete
        </Button>
      </Modal.Body>
    </Modal>
  );
}

export default function MyProjects() {
  const { user, status, setStatus, plan } = React.useContext(AppContext);
  const enabledFoldersFeature = React.useMemo(
    () => !!user?.UIFLAGS.ENABLE_FOLDERS_FEATURE,
    [user?.UIFLAGS.ENABLE_FOLDERS_FEATURE]
  );
  const loadedSequences = loadedSequencesState.use();
  const sequencesTotalCount = sequencesTotalCountState.use();
  const sequencesSharedCount = sequencesSharedCountState.use();
  const [, setShowTutorials] = React.useState(false);
  const [isFetching, setIsFetching] = React.useState(!sequencesInitializedState.get());
  const [activeTab, setActiveTab] = React.useState(PROJECTS.SELF);
  const fetching = React.useRef(false);
  const page = React.useRef(2);
  const images = React.useRef([]);
  const projectsRef = React.useRef();
  const parentElement = React.useRef();
  const pendingClones = pendingClonesState.use();

  const [searchText, setSearchText] = React.useState('');
  const [foldersList, setFoldersList] = React.useState([]);
  const [currentFolderSid, setCurrentFolderSid] = React.useState('');
  const [sequenceSidDragged, setSequenceSidDragged] = React.useState(null);
  const [showAddFolderModal, setShowAddFolderModal] = React.useState(false);
  const projectFolderInputRef = React.useRef(null);
  const projectFolderInputRefRename = React.useRef(null);

  const [verifyFolderDeleteSid, setVerifyFolderDeleteSid] = React.useState(false);
  const [verifyFolderRenameSid, setVerifyFolderRenameSid] = React.useState(false);
  const [isOptionsMenuOpened, setIsOPtionsMenuOpened] = React.useState({});
  const [draggedClassName, setDraggedClassName] = React.useState();

  const deleteText = 'By deleting this folder all of the raw files will be placed\n in the main projects tab';
  const deleteQuestion = 'Are you sure you want to delete this folder?';

  const scrollingContainerRef = React.useRef();
  const [hoveredFolder, setHoveredFolder] = React.useState(null);

  const sequencesInitialized = sequencesInitializedState.use();
  React.useEffect(() => sequencesInitialized && setIsFetching(false), [sequencesInitialized]);

  const inTab = (seq) =>
    activeTab === PROJECTS.SELF ? seq.role === Sequence.ROLE.CREATOR : seq.role !== Sequence.ROLE.CREATOR;
  const currentSequences = loadedSequences.filter(
    (seq) => inTab(seq) && (seq.status === Sequence.STATUS.ACTIVE || seq.status === Sequence.STATUS.TRANSLATING)
  );

  const projectRef = React.useRef();
  const shareRef = React.useRef();

  React.useEffect(() => {
    if (enabledFoldersFeature && user && (!foldersList?.length || !currentFolderSid)) {
      getFoldersList();
    }
  }, [currentFolderSid, user]);

  const getFoldersList = () => {
    console.log('in getFoldersList');
    FolderStructure.list({
      typeEqual: FolderStructure.FOLDER_TYPE.SEQUENCE,
    }).then((res) => {
      setFoldersList(res);
    });
  };

  const projectItems = [
    { name: `My Projects (${sequencesTotalCount})`, value: TABS.PROJECTS, ref: projectRef },
    { name: `Shared with me (${sequencesSharedCount})`, value: TABS.SHARE_WITH_ME, ref: shareRef },
  ];
  const [projectTabs, setprojectTabs] = React.useState(TABS.PROJECTS);

  function SwitchTabs(tab) {
    if (projectTabs !== tab) {
      setprojectTabs(tab);
    }
  }

  React.useEffect(() => {
    if (projectsRef.current) {
      parentElement.current = projectsRef.current.parentNode;
      function handleScroll() {
        const { scrollTop, offsetHeight } = parentElement.current;
        if (offsetHeight + scrollTop >= projectsRef.current.clientHeight) {
          fetchMore();
        }
      }
      if (parentElement.current) {
        parentElement.current.addEventListener('scroll', handleScroll);
        return () => parentElement.current.removeEventListener('scroll', handleScroll);
      }
    }
  }, [projectsRef.current]);

  const headerSizeRef = useResizeObserver();
  const foldersSizeRef = useResizeObserver();

  const filesContainerHeight = React.useMemo(() => {
    const foldersVar = !currentFolderSid && foldersSizeRef?.height ? foldersSizeRef?.height + 40 : -20;
    return currentFolderSid
      ? `calc(100vh - 56px - ${headerSizeRef.height}px - ${foldersVar}px - 10px)`
      : `calc(100vh - 56px - ${headerSizeRef.height}px - ${foldersVar}px)`;
  }, [headerSizeRef?.height, foldersSizeRef?.height]);

  function onAddImage(img) {
    images.current.push(img);
  }

  function onSelect(sequenceSid) {
    images.current.forEach((img) => (img.src = ''));

    if (status) {
      status.sid = sequenceSid;
    } else {
      const stat = new Status(sequenceSid, user.sid, user.orgSid);
      setStatus(stat);
    }
  }

  async function fetchMore() {
    if (fetching.current || !page.current || !sequencesInitialized) {
      return;
    }

    fetching.current = true;
    setIsFetching(true);
    const projectsCount = sequencesAmount();
    let sequenceListFilter =
      activeTab === PROJECTS.SELF
        ? { userSidEqual: user.sid, statusEqual: 1, roleEqual: 1 }
        : { userSidEqual: user.sid, statusEqual: 1, roleNotEqual: 1 };
    const items = await Sequence.list(
      sequenceListFilter,
      {
        pageSize: projectsCount || PAGE_SIZE,
        pageIndex: projectsCount ? 2 : page.current,
      },
      {
        sid: true,
        insertTime: true,
        status: true,
        chapters: {
          sid: true,
          status: true,
          assetSid: true,
          contentVersion: true,
          thumbnailVersion: true,
        },
        duration: true,
        title: true,
        role: true,
        folderSid: true,
        accessesCount: true,
        accesses: {
          userSid: true,
          role: true,
          email: true,
          name: true,
          thumbUrl: true,
          creator: true,
        },
        aspectRatio: true,
        languageCode: true,
      }
    );

    setIsFetching(false);
    if (items.length) {
      appendSequences(...items);
    }

    if (items.length < PAGE_SIZE) {
      if (page.current === 1 && items.length < 4) {
        setShowTutorials(true);
      }
      page.current = null;
    } else {
      page.current++;
    }
    fetching.current = false;
  }

  const onSearchTextChange = (e) => {
    setSearchText(e.target.value);
  };

  if (!user) {
    return null;
  }

  if (!sequencesInitialized) {
    return (
      <div className="init-loader">
        <Loader style={{ display: 'block' }} />
      </div>
    );
  }

  async function createNewFolder(parentSid) {
    setShowAddFolderModal(false);
    const name = projectFolderInputRef.current.value;
    const folder = new FolderStructure();
    folder.name = name;
    folder.type = FolderStructure.FOLDER_TYPE.SEQUENCE;
    try {
      const newFolder = await folder.save();
      setFoldersList((state) => [newFolder, ...(state || [])]);
    } catch (err) {
      console.error('error ', err);
    }
    folder.save();
  }

  const isNewFolderNameNotUnique = (str) => {
    return !!foldersList.find((folder) => folder?.name?.toUpperCase().trim() === str?.toUpperCase().trim());
  };

  async function deleteFolder(sid) {
    let folderForDeleting;
    await FolderStructure.get(sid).then((res) => (folderForDeleting = res));
    if (folderForDeleting) {
      try {
        await folderForDeleting.remove();
        setFoldersList((state) => state.filter((folder) => folder.sid !== sid));
        loadedSequencesState.set((sequences) => {
          sequences.forEach((seq) => seq.folderSid === sid && (seq.folderSid = null));
          return sequences;
        });
      } catch (err) {
        console.error(err);
      }
    }
    setVerifyFolderDeleteSid(false);
    setCurrentFolderSid('');
  }

  const getVideosAmount = (sid) => {
    let amount = 0;
    if (currentSequences && currentSequences.length) {
      amount = currentSequences.filter((seq) => seq.folderSid === sid).length;
    }
    return `${amount} ${pluralize('videos', amount)}`;
  };

  async function renameFolder() {
    const name = projectFolderInputRefRename.current.value;
    try {
      let folderForRenaming = await FolderStructure.get(verifyFolderRenameSid);
      folderForRenaming.name = name;
      await folderForRenaming.save();
      setFoldersList((state) => {
        let stateCopy = [...state];
        let arr = stateCopy.filter((f) => f.sid !== folderForRenaming.sid);
        arr.push(folderForRenaming);
        return arr;
      });
    } catch (err) {
      console.error(err);
    }
    setVerifyFolderRenameSid(false);
    setCurrentFolderSid('');
    projectFolderInputRefRename.current = null;
  }

  if (!user) {
    return null;
  }

  return (
    <>
      <div className="contents" style={{ height: '100%' }}>
        <Helmet>
          <style>{'body {background-color: #FAFAFA;}'}</style>
        </Helmet>
        <div className="projects">
          {/* Header */}

          <div ref={headerSizeRef.ref} className={classNames('assets-group-header projects')}>
            <div className="tabs-group">
              {enabledFoldersFeature && (
                <div
                  className="page-title"
                  onClick={() => setCurrentFolderSid('')}
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={(e) => {
                    if (activeTab === TABS.PROJECTS) {
                      return onDropActions(null, currentSequences, sequenceSidDragged);
                    }
                  }}
                >
                  Projects Library
                </div>
              )}

              {!enabledFoldersFeature ? (
                <Tabs tabsItems={projectItems} onChange={(selectedTab) => setActiveTab(selectedTab)} />
              ) : !currentFolderSid ? (
                <Tabs tabsItems={projectItems} onChange={(selectedTab) => setActiveTab(selectedTab)} />
              ) : (
                <div className="page-subtitle" style={{ paddingBottom: '3px' }}>
                  <div className="page-subtitle-arrow">{'>'}</div>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id="page-subtitle-text-tooltip" className="tooltip-peech-light">
                        {foldersList?.find((f) => f.sid === currentFolderSid)?.name || ''}
                      </Tooltip>
                    }
                  >
                    <div className="page-subtitle-text">
                      {foldersList?.find((f) => f.sid === currentFolderSid)?.name || ''}
                    </div>
                  </OverlayTrigger>
                </div>
              )}
            </div>

            <div className="actions-group">
              <ProjectsCounterBlock />
            </div>

            {enabledFoldersFeature && (
              <CreateModal
                showModal={showAddFolderModal}
                setShowModal={setShowAddFolderModal}
                modalTitle="New folder name"
                innerRef={projectFolderInputRef}
                addButton="Add new folder"
                createItem={createNewFolder}
                svgName={'library'}
                disabled={isNewFolderNameNotUnique}
              />
            )}
          </div>

          <div className="projects-group">
            <div
              className="projects-group-content-wrapper"
              ref={scrollingContainerRef}
              style={{
                height: filesContainerHeight,
              }}
            >
              {currentSequences && (
                <Row
                  className={classNames('projects-group-content', { 'not-empty': currentSequences.length })}
                  ref={projectsRef}
                >
                  {/* Folders */}

                  {enabledFoldersFeature && !currentFolderSid && activeTab === TABS.PROJECTS && (
                    <div className="part-title">
                      Folders
                      <div className="search-container">
                        <div className="search-wrapper">
                          <SVG name="search" className="search" />
                          <input
                            type="text"
                            id="search-input"
                            name="search-input"
                            placeholder="Search here"
                            onChange={onSearchTextChange}
                          />
                        </div>

                        <div
                          className="add-folder-wrapper"
                          onClick={() => {
                            setShowAddFolderModal(true);
                          }}
                        >
                          <SVG name="add-folder" className="add-folder" />
                        </div>
                      </div>
                    </div>
                  )}

                  {enabledFoldersFeature &&
                    !currentFolderSid &&
                    activeTab === TABS.PROJECTS &&
                    foldersList.map((item, index) => {
                      const folderSubTitle = getVideosAmount(item.sid);
                        return (
                          <Folder
                            key={index}
                            item={item}
                            onFolderClick={() => setCurrentFolderSid(item.sid)}
                            onDropActions={onDropActions}
                            subTitle={folderSubTitle}
                            verifyFolderDeleteSid={verifyFolderDeleteSid}
                            setVerifyFolderDeleteSid={setVerifyFolderDeleteSid}
                            deleteFolder={deleteFolder}
                            verifyFolderRenameSid={verifyFolderRenameSid}
                            setVerifyFolderRenameSid={setVerifyFolderRenameSid}
                            renameFolder={renameFolder}
                            innerRef={projectFolderInputRefRename}
                            disabled={isNewFolderNameNotUnique}
                            currentFolderSid={currentFolderSid}
                            isOptionsMenuOpened={isOptionsMenuOpened}
                            setIsOPtionsMenuOpened={setIsOPtionsMenuOpened}
                            deleteText={deleteText}
                            deleteQuestion={deleteQuestion}
                            allItems={currentSequences}
                            sidDragged={sequenceSidDragged}
                            scrollingContainerRef={scrollingContainerRef}
                            hoveredFolder={hoveredFolder}
                            setHoveredFolder={setHoveredFolder}
                          />
                        );
                      })}

                  {enabledFoldersFeature &&
                  currentSequences.length &&
                  !currentFolderSid &&
                  activeTab === TABS.PROJECTS ? (
                    <div className="part-title">Files</div>
                  ) : null}

                  {/* Files */}

                  {
                    // TODO: use ImageWithPlaceholder?
                    pendingClones.map((pendingClone) => (
                      <Col xs="12" md="6" lg="4" xl="3" xxl="2" className="projects-col" key={pendingClone.id}>
                        <SequenceItemPlaceholder src={pendingClone.thumbnail}>
                          <img src={pendingClone.thumbnail} alt="pending clone thumbnail" />
                          <SequenceItemPlaceholderOverlay>
                            <Loader size="sm" />
                          </SequenceItemPlaceholderOverlay>
                        </SequenceItemPlaceholder>

                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            overflow: 'hidden',
                            maxWidth: '100%',
                          }}
                        >
                          <div className="projects-item-title">{pendingClone.title}</div>
                          <AspectRatioLabel className="projects-item-title">
                            {pendingClone.aspectRatio}
                          </AspectRatioLabel>
                        </div>
                      </Col>
                    ))
                  }

                  {enabledFoldersFeature
                    ? currentSequences
                        .filter((seq) => {
                          if ((!currentFolderSid && !seq.folderSid) || seq.folderSid === currentFolderSid) {
                            return seq;
                          }
                        })
                        .filter((seq) =>
                          !searchText ? seq : seq?.title?.toUpperCase().includes(searchText?.toUpperCase().trim())
                        )
                        .map((sequence) => (
                          <SequenceItem
                            key={sequence.sid}
                            sequenceItem={sequence}
                            onRemove={() => unloadSequence(sequence.sid)}
                            onAddImage={onAddImage}
                            onSelect={onSelect}
                            shouldShowActions={activeTab === PROJECTS.SELF}
                            draggable={activeTab === TABS.PROJECTS}
                            onDragStart={(e) =>
                              dragToFolderStartHandler(e, sequence.sid, setDraggedClassName, setSequenceSidDragged)
                            }
                            onDragEnd={(e) =>
                              dragToFolderEndHandler(
                                e,
                                sequence.sid,
                                draggedClassName,
                                setSequenceSidDragged,
                                setDraggedClassName
                              )
                            }
                            scrollingContainerRef={scrollingContainerRef}
                          />
                        ))
                    : currentSequences.map((sequence) => (
                        <SequenceItem
                          key={sequence.sid}
                          sequenceItem={sequence}
                          onRemove={() => unloadSequence(sequence.sid)}
                          onAddImage={onAddImage}
                          onSelect={onSelect}
                          shouldShowActions={activeTab === PROJECTS.SELF}
                          scrollingContainerRef={scrollingContainerRef}
                        />
                      ))}

                  {!currentSequences.length && !isFetching && (
                    <div>
                      {activeTab === TABS.PROJECTS ? (
                        <div className="projects-group-empty">
                          <div className="no-projects-wrapper">
                            <div className="title-no-projects-big">Time to create your first project!</div>
                            <div className="title-no-projects-small">It takes just two minutes...</div>
                            <div className="button-no-projects-container">
                              {plan ? <CreateProject renderPlace="projects" /> : null}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="projects-group-empty">
                          <SVG
                            name="empty-illustration"
                            className="empty-illustration-icon"
                            viewBox="0 0 95 84"
                            width="95px"
                            height="84px"
                          ></SVG>
                          <div className="titles-continer">
                            <h3 className="first-title">
                              “{activeTab === PROJECTS.SELF ? 'My projects' : 'Shared with me'}“ is empty
                            </h3>
                            <span className="sub-title">
                              {activeTab === PROJECTS.SELF
                                ? 'To add projects here, create a new project'
                                : 'No one has shared projects with you yet '}{' '}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </Row>
              )}

              <LoaderDiv active={isFetching}>
                <Loader />
              </LoaderDiv>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
